<template>
  <v-container fluid>
    <v-dialog :value="!evento.idTipoEvento" max-width="800px" persistent>
      <v-card class="pa-2">
        <h2 class="text-h5 text-center ma-2">Tipo de evento</h2>
        <v-divider></v-divider>
        <div class="d-flex flex-wrap justify-space-around mt-2">
          <v-card
            v-for="tipo in opciones.tipoEventos"
            :key="tipo.idTipoEvento"
            @click.stop="escoge(tipo.idTipoEvento)"
            class="pa-2 ma-1 d-flex flex-column justify-space-around"
            min-width="150px"
            height="150px"
            outlined
          >
            <div class="text-h6 text-center">{{ tipo.tipo }}</div>
            <v-icon>{{ tipo.icon }}</v-icon>
          </v-card>
        </div>
      </v-card>
    </v-dialog>

    <v-form ref="form">
      <div class="d-flex mb-2">
        <v-btn
          :loading="sending"
          @click.stop="sendEvento"
          large
          color="primary"
          class="mr-1"
        >
          <v-icon left>mdi-content-save</v-icon>
          Guardar</v-btn
        >

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              large
              text
              class="mx-1"
              @click.stop="errors = !validar()"
              :color="errors == null ? '' : errors ? 'error' : 'success'"
            >
              <v-icon left v-if="errors === true"
                >mdi-alert-circle-outline</v-icon
              >
              <v-icon left v-else-if="errors === false">mdi-check</v-icon
              >Consultar campos
            </v-btn>
          </template>
          <span v-if="!errors">Consultar campos faltantes o erroneos</span>
          <span v-else>Hay campos erróneos, revisa todos los campos</span>
        </v-tooltip>

        <v-spacer></v-spacer>
        <v-btn
          v-if="evento.idTipoEvento == 5"
          @click.stop="vincularBoda"
          large
          color="success"
          class="mx-1"
        >
          <v-icon left>mdi-sync</v-icon>
          Vincular</v-btn
        >
        <v-btn
          :loading="sending"
          @click.stop="duplicarEvento"
          large
          color="info"
          class="mx-1"
        >
          <v-icon left>mdi-content-copy</v-icon>
          Duplicar</v-btn
        >
        <v-btn
          :loading="sending"
          @click.stop="deleteEvent"
          large
          color="error"
          class="mx-1"
        >
          <v-icon left>mdi-trash-can-outline</v-icon>
          Eliminar</v-btn
        >
      </div>

      <div class="d-flex flex-wrap my-2">
        <v-alert
          class="ma-0"
          text
          v-if="evento.idTipoEvento"
          dense
          prominent
					:icon="tipoEvento.icon"
					:color="tipoEvento.color"
        >
          {{ tipoEvento.tipo }}
          <v-btn
            icon
            @click.stop="$delete(evento, 'idTipoEvento')"
						class="ml-4"
          >
            <v-icon>mdi-restore</v-icon>
          </v-btn>
        </v-alert>
      </div>

      <v-card class="pa-2">
        <v-row>

					<v-col cols="12" md="4">
            <tarjeta-datos titulo="Datos celebración" icon="party-popper">

							<template v-for="(input, index) in datosCelebracion">
								<AuxInput :title="input.titulo" >
									<InputFormulario :rules="input.rules" :input="input" :value="input.value" @input="input.input" :options="input.options" />
								</AuxInput>
								<v-divider></v-divider>
							</template>

            </tarjeta-datos>
            
            <tarjeta-datos titulo="Presupuesto" icon="file-sign" class="mt-6">
              <AuxInput title="Solicitud presupuesto">
                <v-checkbox v-model="existePpto"></v-checkbox>
              </AuxInput>
              <v-divider></v-divider>
              <template v-if="existePpto">
                <template v-for="(input, index) in datosPresupuesto">
                  <AuxInput :title="input.titulo">
                    <InputFormulario :rules="input.rules" :input="input" :value="input.value" @input="input.input" :options="input.options"/>
                  </AuxInput>
                  <v-divider></v-divider>
                </template>
                <AuxInput title="Abrir ficha presupuesto">
                  <v-checkbox v-model="abrirPpto"></v-checkbox>
                </AuxInput>
							</template>
            </tarjeta-datos>

            <!-- <tarjeta-datos class="mt-6" titulo="FEEDBACK" icon="frequently-asked-questions" v-if="tipoEvento && tipoEvento.idTipoEvento != 5 && tipoEvento.idTipoEvento != 6">
              <template v-for="(input, index) in datosFeedback">
								<AuxInput :title="input.titulo" >
									<InputFormulario :rules="input.rules" :input="input" :value="input.value" @input="input.input" :options="input.options" />
								</AuxInput>
								<v-divider></v-divider>
							</template>
            </tarjeta-datos> -->

            <tarjeta-datos class="mt-6" titulo="Otros" icon="cog">
              <aux-input title="Intolerancias">
                <v-textarea
                  v-model="evento.intolerancias"
                  filled
                  dense
                  hide-details="auto"
                  no-resize
                ></v-textarea>
              </aux-input>
            </tarjeta-datos>
          </v-col>

					<v-col cols="12" md="4">
            <tarjeta-datos titulo="Datos Evento" icon="calendar">
							<aux-input title="Fecha Evento">
                <v-date-picker
									:first-day-of-week="1"
                  landscape
                  no-title
                  full-width
                  filled
                  dense
                  hide-details="auto"
                  :min="new Date().toISOString().slice(0, 10)"
                  v-model="evento.fechaInicioEventoAux"
                  @change=" (v) => (evento.fechaInicioEvento = new Date(v) .toISOString() .slice(0, 19) .replace('T', ' '))"
                  :rules="[rules.req]"
                  color="primary"
        					:events="eventosCalendario"
                ></v-date-picker>
                <div v-if="evento.fechaInicioEvento" class="d-flex mt-1">
                  <span>{{ parseDate(evento.fechaInicioEvento) }}</span>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click.stop="
                      evento.fechaInicioEventoAux = null;
                      evento.fechaInicioEvento = null;
                    "
                    x-small
                    icon
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </div>
								<span v-else class="error--text">
									<v-icon small left color="error">mdi-alert-outline</v-icon>
									Este campo es obligatorio
								</span>
              </aux-input>
              <v-divider></v-divider>
              <aux-input title="Estado Evento" v-if="evento.idEvento">
                <div class="d-flex my-3">
                  <v-chip
                    class="v-chip--active px-6 font-weight-bold"
                    @click.stop="cambioEstado = evento.idEvento"
                    outlined
                    label
                    x-large
                    :color="evento.colorEstadoEvento"
                  >
                    <v-icon left>{{ evento.iconEstadoEvento }}</v-icon>
                    {{ evento.estado }}
                    <v-icon right>mdi-pencil</v-icon>
                  </v-chip>
                </div>
              </aux-input>
              <aux-input title="Estado Evento" v-if="!evento.idEvento">
                <v-select :rules="[rules.req]" filled dense v-model="idEstado" :items="[{estado : 'Pendiente', idEstado : 1},{estado : 'Confirmado', idEstado : 2}]" :item-text="item => item.estado" :item-value="item => item.idEstado"></v-select>
              </aux-input>
              <v-divider></v-divider>
							<template v-for="(input, index) in datosEvento">
								<AuxInput :title="input.titulo" >
									<InputFormulario :rules="input.rules" :input="input" :value="input.value" @input="input.input" :options="input.options" />
								</AuxInput>
								<v-divider></v-divider>
							</template>

            </tarjeta-datos>
          </v-col>

          <v-col cols="12" md="4" v-if="tipoEvento && tipoEvento.idTipoEvento != 5 && tipoEvento.idTipoEvento != 6" >
            <tarjeta-datos titulo="Datos Clientes" icon="account">
              <template v-for="(cli, index) in evento.clientes">
                <div>
                  <div class="d-flex align-center justify-space-between">
                    <v-subheader>Cliente {{ index + 1 }}</v-subheader>
                    <v-btn
                      @click.stop="evento.clientes.splice(index, 1)"
                      small
                      icon
                      class="mr-3"
                      v-if="index > 0"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                  <aux-input title="Nombre Completo">
                    <v-text-field
                      v-model="cli.nombreCompleto"
                      filled
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </aux-input>
                  <v-divider></v-divider>
                  <aux-input title="Teléfono">
                    <v-text-field
                      v-model="cli.telefono"
                      filled
                      dense
                      hide-details="auto"
                    ></v-text-field>
										<v-checkbox v-model="cli.ignorarTelefono" label="FICHA O PRESUPUESTO"></v-checkbox>
                  </aux-input>
                  <v-divider></v-divider>
                  <aux-input title="Email">
                    <v-text-field
                      v-model="cli.email"
                      filled
                      dense
                      hide-details="auto"
                    ></v-text-field>
										<v-checkbox v-model="cli.ignorarEmal" label="FICHA O PRESUPUESTO"></v-checkbox>
                  </aux-input>
                  <v-divider></v-divider>
                  <aux-input title="Procedencia">
                    <v-text-field
                      v-model="cli.procedencia"
                      filled
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </aux-input>
                  <v-divider></v-divider>
                </div>
              </template>
              <v-btn
                class="ma-2"
                small
                fab
                color="primary"
                @click.stop="evento.clientes.push({})"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </tarjeta-datos>
          </v-col>
        </v-row>
      </v-card>
    </v-form>

    <ConfirmDialog ref="confirm" />
    <VincularBoda ref="vincular"></VincularBoda>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      :value="cambioEstado"
      @input="(v) => (v === false ? (cambioEstado = null) : null)"
    >
      <v-toolbar dense>
        <v-toolbar-title>Cambiar estado {{ cambioEstado }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="cambioEstado = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <cambio-estado
        @close="cambioEstado = null"
        @reload="getEvento"
        :key="cambioEstado"
        :idEvento="cambioEstado"
      ></cambio-estado>
    </v-dialog>
  </v-container>
</template>

<script>
import { req, isNumber, phone, email } from "@/utils/validations.js";
import { parseDate } from "@/utils/index.js";

export default {
  components: {
    AuxInput: () => import("@/components/AuxInput.vue"),
    InputFormulario: () => import("../components/InputFormulario.vue"),
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    VincularBoda: () => import("../components/VincularBoda.vue"),
    CambioEstado: () => import("../components/CambiarEstado.vue"),
    CambioEstadoNuevo: () => import("../components/CambiarEstadoNuevo.vue"),
  },
  data() {
    return {
      idPresupuesto: null,
      existePpto: false,
      abrirPpto: false,
      vinculame: false,
      presupuesto: {
        fecha: null,
        estado: null,
        confirmado: null,
        fechaEv: null,
        tipo: null,
        cliente: null,
        espacio: null,
        ubicacion: null,
        tipoPpto: null,
        introduce: null,
        realiza: null,
        supervisa: null,
        notas: null,
      },
      errors: null,
      evento: { clientes: [{}] },
      opciones: {
        tipoEventos: [],
        tipoBoda: [],
        espacios: [],
        salones: [],
        estados: [],
        viasReserva: [],
      },
      idEstado: null,
      estado: null,
      estados: [],
      sending: false,
      rules: { req, isNumber, phone, email },
      cambioEstado: null,
			weekdays: [1, 2, 3, 4, 5, 6, 0],
			eventos: [],
      introduce:[
        {title:"LAURA", val : 1}, 
        {title:"BERTA", val : 2}, 
        {title:"ISABEL", val : 3}, 
        {title:"MONICA", val : 4}, 
        {title:"BELEN", val : 10}, 
        {title:"JAVI", val : 5}, 
        {title:"JOSE", val : 6}, 
        {title:"OTROS", val : 7}
      ],
      realiza:[
        {title:"LAURA", val : 1}, 
        {title:"BERTA", val : 2}, 
        {title:"ISABEL", val : 3}, 
        {title:"MONICA", val : 4}, 
        {title:"BELEN", val : 10}, 
        {title:"OTROS", val : 7}
      ],
      feedback:[
        {title:"NS / NC", val : 1}, 
        {title:"Valoración Negativa", val : 2}, 
        {title:"Valoración Neutra", val : 3}, 
        {title:"Valoración Positiva", val : 4}, 
      ],
    };
  },
  computed: {
    tipoEvento() {
      if (!this.evento.idTipoEvento) return null;
      return this.opciones.tipoEventos.find(
        (t) => t.idTipoEvento == this.evento.idTipoEvento
      );
    },
    espacio() {
      if (!this.evento.idEspacio) return null;
      return this.opciones.espacios.find(
        (t) => t.idEspacio == this.evento.idEspacio
      );
    },
    datosPresupuesto() {
      const setKey = (key, value) => {
				this.$set(this.presupuesto, key, value);
			}

			if ( !this.tipoEvento || this.tipoEvento.idTipoEvento == 6) return []
      
      return [
        { titulo: 'Introduce', type: 'select',   rules: [],   value: this.presupuesto.introduce,  input: (v) => setKey('introduce', v), options: this.introduce, itemValue: 'val', itemText: 'title' },
				{ titulo: 'Realiza',   type: 'select',   rules: [],   value: this.presupuesto.realiza,    input: (v) => setKey('realiza', v),   options: this.realiza,   itemValue: 'val', itemText: 'title' },
				{ titulo: 'Notas',     type: 'textarea', rules: [],   value: this.presupuesto.notas,      input: (v) => setKey('notas', v) },
			]

    },
    datosFeedback() {
      const setKey = (key, value) => {
				this.$set(this.evento, key, value);
			}

			if ( !this.tipoEvento || this.tipoEvento.idTipoEvento == 6) return []

      // BODAS
			if (this.tipoEvento.idTipoEvento == 1) return [
				{ titulo: 'Feedback',  type: 'select', rules: [], value: this.evento.feedback,  input: (v) => setKey('feedback', v), options: this.feedback, itemValue: 'val', itemText: 'title' },
				{ titulo: 'Bodas.net', type: 'bool',   rules: [], value: this.evento.bodasnet,  input: (v) => setKey('bodasnet', v) },
			]
      else return [
				{ titulo: 'Feedback',  type: 'select', rules: [], value: this.evento.feedback,  input: (v) => setKey('feedback', v), options: this.feedback, itemValue: 'val', itemText: 'title' },
			] 
    },
		datosCelebracion() {
			const setKey = (key, value) => {
				this.$set(this.evento, key, value);
			}

			if ( !this.tipoEvento ) return []

			// BODAS
			if (this.tipoEvento.idTipoEvento == 1) return [
				{ titulo: 'Novios', type: 'text', rules: [req], value: this.evento.novios,  input: (v) => setKey('novios', v)},
				{ titulo: 'Breve Dato', type: 'text', value: this.evento.breveDato,  input: (v) => setKey('breveDato', v)},
				{ titulo: 'Enlace civil', type: 'bool', value: this.evento.enlaceCivil,  input: (v) => setKey('enlaceCivil', v)},
				{ titulo: 'Tipo Boda', options: this.opciones.tipoBoda, type: 'select', rules: [], value: this.evento.idTipoBoda,  input: (v) => setKey('idTipoBoda', v), itemValue: 'idTipoBoda', itemText: 'nombreTipo'},
				{ titulo: 'Vía Reserva', options: this.opciones.viasReserva, type: 'select', rules: [], value: this.evento.idViaReserva,  input: (v) => setKey('idViaReserva', v), itemValue: 'idViaReserva', itemText: 'nombreVia'},
				// { titulo: 'Tipo boda', type: 'select', value: this.evento.idTipoBoda},
				{ titulo: 'Necesidad Habitaciones', type: 'bool', value: this.evento.habitaciones, input: (v) => setKey('habitaciones', v)},
				{ titulo: 'Observaciones', type: 'textarea', value: this.evento.observaciones, input: (v) => setKey('observaciones', v)},
			]

			// EVENTOS
			if (this.tipoEvento.idTipoEvento == 3) return [
				{ titulo: 'Tipo Evento / Celebración', type: 'text', rules: [req], value: this.evento.tipoCelebracion,  input: (v) => setKey('tipoCelebracion', v)},
				{ titulo: 'Breve Dato', type: 'text', value: this.evento.breveDato,  input: (v) => setKey('breveDato', v)},
				{ titulo: 'Observaciones', type: 'textarea', value: this.evento.observaciones, input: (v) => setKey('observaciones', v)},
			]

			// EMPRESAS
			if (this.tipoEvento.idTipoEvento == 4) return [
				{ titulo: 'Tipo Evento / Celebración', type: 'text', rules: [req], value: this.evento.tipoCelebracion,  input: (v) => setKey('tipoCelebracion', v)},
				{ titulo: 'Nombre Empresa', type: 'text', rules: [req], value: this.evento.empresa,  input: (v) => setKey('empresa', v)},
				{ titulo: 'Breve Dato', type: 'text', value: this.evento.breveDato,  input: (v) => setKey('breveDato', v)},
				{ titulo: 'Observaciones', type: 'textarea', value: this.evento.observaciones, input: (v) => setKey('observaciones', v)},
			]

			// COMUNIONES
			if (this.tipoEvento.idTipoEvento == 2) return [
				{ titulo: 'Nombre Comulgante', type: 'text', rules: [req], value: this.evento.nino,  input: (v) => setKey('nino', v)},
				{ titulo: 'Nombre Padres', type: 'text', rules: [req], value: this.evento.padres,  input: (v) => setKey('padres', v)},
				{ titulo: 'Breve Dato', type: 'text', value: this.evento.breveDato,  input: (v) => setKey('breveDato', v)},
				{ titulo: 'Parroquia', type: 'text', rules: [req], value: this.evento.parroquia,  input: (v) => setKey('parroquia', v)},
				{ titulo: 'Observaciones', type: 'textarea', value: this.evento.observaciones, input: (v) => setKey('observaciones', v)},
			]

			// PRUEBAS BODA
			if (this.tipoEvento.idTipoEvento == 5) return [
				{ titulo: 'Prueba Boda - Novios', type: 'text', rules: [req], value: this.evento.novios,  input: (v) => setKey('novios', v)},
				{ titulo: 'Breve Dato', type: 'text', value: this.evento.breveDato,  input: (v) => setKey('breveDato', v)},
				{ titulo: 'Fecha boda Real', type: 'text', rules: [req], value: this.evento.fechaBodaReal,  input: (v) => setKey('fechaBodaReal', v)},
				{ titulo: 'Ubicación Boda Real', type: 'text', rules: [req], value: this.evento.ubBodaReal,  input: (v) => setKey('ubBodaReal', v)},
				{ titulo: 'Observaciones', type: 'textarea', value: this.evento.observaciones, input: (v) => setKey('observaciones', v)},
			]

			// BLOQUEO
			if (this.tipoEvento.idTipoEvento == 6) return [
				{ titulo: 'Tipo Evento / Celebración', type: 'select', options: ['CIERRE RESERVAS GLOBAL (LC+MULTI+SAM)', 'CIERRE RESERVAS LC', 'CIERRE RESERVAS SAM','BLOQUEO SALÓN'], rules: [req], value: this.evento.tipoCelebracion,  input: (v) => setKey('tipoCelebracion', v)},
				{ titulo: 'Motivo Bloqueo', type: 'textarea', rules: [req], value: this.evento.observaciones, input: (v) => setKey('observaciones', v)},
			]
		},
		datosEvento() {
			const setKey = (key, value) => {
				this.$set(this.evento, key, value);
			}

			if ( !this.tipoEvento ) return []

			// BODAS
			if (this.tipoEvento.idTipoEvento == 1) return [
				{ titulo: 'Espacio', options: this.opciones.espacios, type: 'select', rules: [req], value: this.evento.idEspacio,  input: (v) => setKey('idEspacio', v), itemValue: 'idEspacio', itemText: (item) => `${item.nombreEspacio} ${item.siglas ? `(${item.siglas})` : '' } `},
				{ titulo: 'Salón', placeholder: '- No hay datos disponibles -', options: this.opciones.salones, type: 'select', value: this.evento.idSalon,  input: (v) => setKey('idSalon', v), itemValue: 'idSalon',  itemText: (item) => `${item.nombreSalon} (${item.siglas})`, filter: (this.espacio && !this.espacio.libre)  },
				{ titulo: 'Ciudad CAT', type: 'text', value: this.evento.ciudadCat, input: (v) => setKey('ciudadCat', v), filter: (this.espacio && this.espacio.libre)},
				{ titulo: 'Horario', options: this.opciones.horarios, type: 'select', rules: [req], value: this.evento.idHorario,  input: (v) => setKey('idHorario', v), itemValue: 'idHorario', itemText: (item) => `${item.nombreHorario} (${item.siglas})`  },
				{ titulo: 'Nº Adultos', type: 'text', rules: [isNumber], value: this.evento.nAdultos,  input: (v) => setKey('nAdultos', v)},
				{ titulo: 'Nº Niños', type: 'text', rules: [isNumber], value: this.evento.nNinos,  input: (v) => setKey('nNinos', v)},
				{ titulo: 'Ubicación Enlace', type: 'text', value: this.evento.ubiEnlace,  input: (v) => setKey('ubiEnlace', v)},
				{ titulo: 'Ubicación Aperitivo', type: 'text', value: this.evento.ubiAperitivo,  input: (v) => setKey('ubiAperitivo', v)},
			].filter(input => input.filter == null || input.filter )

			// EVENTOS
			if (this.tipoEvento.idTipoEvento == 3) return [
				{ titulo: 'Espacio', options: this.opciones.espacios, type: 'select', rules: [req], value: this.evento.idEspacio,  input: (v) => setKey('idEspacio', v), itemValue: 'idEspacio', itemText: (item) => `${item.nombreEspacio} ${item.siglas ? `(${item.siglas})` : '' } `},
				{ titulo: 'Salón', placeholder: '- No hay datos disponibles -', options: this.opciones.salones, type: 'select', value: this.evento.idSalon,  input: (v) => setKey('idSalon', v), itemValue: 'idSalon',  itemText: (item) => `${item.nombreSalon} (${item.siglas})`, filter: (this.espacio && !this.espacio.libre)  },
				{ titulo: 'Ciudad CAT', type: 'text', value: this.evento.ciudadCat, input: (v) => setKey('ciudadCat', v), filter: (this.espacio && this.espacio.libre)},
				{ titulo: 'Horario', options: this.opciones.horarios, type: 'select', rules: [req], value: this.evento.idHorario,  input: (v) => setKey('idHorario', v), itemValue: 'idHorario', itemText: (item) => `${item.nombreHorario} (${item.siglas})`  },
				{ titulo: 'Nº Adultos', type: 'text', rules: [isNumber], value: this.evento.nAdultos,  input: (v) => setKey('nAdultos', v)},
				{ titulo: 'Nº Niños', type: 'text', rules: [isNumber], value: this.evento.nNinos,  input: (v) => setKey('nNinos', v)},
			].filter(input => input.filter == null || input.filter )

			// EMPRESAS
			if (this.tipoEvento.idTipoEvento == 4) return [
				{ titulo: 'Espacio', options: this.opciones.espacios, type: 'select', rules: [req], value: this.evento.idEspacio,  input: (v) => setKey('idEspacio', v), itemValue: 'idEspacio', itemText: (item) => `${item.nombreEspacio} ${item.siglas ? `(${item.siglas})` : '' } `},
				{ titulo: 'Salón', placeholder: '- No hay datos disponibles -', options: this.opciones.salones, type: 'select', value: this.evento.idSalon,  input: (v) => setKey('idSalon', v), itemValue: 'idSalon',  itemText: (item) => `${item.nombreSalon} (${item.siglas})`, filter: (this.espacio && !this.espacio.libre)  },
				{ titulo: 'Ciudad CAT', type: 'text', value: this.evento.ciudadCat, input: (v) => setKey('ciudadCat', v), filter: (this.espacio && this.espacio.libre)},
				{ titulo: 'Horario', options: this.opciones.horarios, type: 'select', rules: [req], value: this.evento.idHorario,  input: (v) => setKey('idHorario', v), itemValue: 'idHorario', itemText: (item) => `${item.nombreHorario} (${item.siglas})`  },
				{ titulo: 'Nº Adultos', type: 'text', rules: [isNumber], value: this.evento.nAdultos,  input: (v) => setKey('nAdultos', v)},
				{ titulo: 'Nº Niños', type: 'text', rules: [isNumber], value: this.evento.nNinos,  input: (v) => setKey('nNinos', v)},
			].filter(input => input.filter == null || input.filter )

			// COMUNIONES
			if (this.tipoEvento.idTipoEvento == 2) return [
				{ titulo: 'Espacio', options: this.opciones.espacios, type: 'select', rules: [req], value: this.evento.idEspacio,  input: (v) => setKey('idEspacio', v), itemValue: 'idEspacio', itemText: (item) => `${item.nombreEspacio} ${item.siglas ? `(${item.siglas})` : '' } `},
				{ titulo: 'Salón', placeholder: '- No hay datos disponibles -', options: this.opciones.salones, type: 'select', value: this.evento.idSalon,  input: (v) => setKey('idSalon', v), itemValue: 'idSalon',  itemText: (item) => `${item.nombreSalon} (${item.siglas})`, filter: (this.espacio && !this.espacio.libre)  },
				{ titulo: 'Exclusivo', type: 'bool', value: this.evento.excl,  input: (v) => setKey('excl', v), itemValue: 'excl' },
				{ titulo: 'Ciudad CAT', type: 'text', value: this.evento.ciudadCat, input: (v) => setKey('ciudadCat', v), filter: (this.espacio && this.espacio.libre)},
				{ titulo: 'Horario', options: this.opciones.horarios, type: 'select', rules: [req], value: this.evento.idHorario,  input: (v) => setKey('idHorario', v), itemValue: 'idHorario', itemText: (item) => `${item.nombreHorario} (${item.siglas})`  },
				{ titulo: 'Nº Adultos', type: 'text', rules: [isNumber], value: this.evento.nAdultos,  input: (v) => setKey('nAdultos', v)},
				{ titulo: 'Nº Niños', type: 'text', rules: [isNumber], value: this.evento.nNinos,  input: (v) => setKey('nNinos', v)},
			].filter(input => input.filter == null || input.filter )

			// PRUEBAS BODA
			if (this.tipoEvento.idTipoEvento == 5) return [
				{ titulo: 'Espacio', options: this.opciones.espacios, type: 'select', rules: [req], value: this.evento.idEspacio,  input: (v) => setKey('idEspacio', v), itemValue: 'idEspacio', itemText: (item) => `${item.nombreEspacio} ${item.siglas ? `(${item.siglas})` : '' } `},
				{ titulo: 'Salón', placeholder: '- No hay datos disponibles -', options: this.opciones.salones, type: 'select', value: this.evento.idSalon,  input: (v) => setKey('idSalon', v), itemValue: 'idSalon',  itemText: (item) => `${item.nombreSalon} (${item.siglas})`, filter: (this.espacio && !this.espacio.libre)  },
				{ titulo: 'Ciudad CAT', type: 'text', value: this.evento.ciudadCat, input: (v) => setKey('ciudadCat', v), filter: (this.espacio && this.espacio.libre)},
				{ titulo: 'Horario', options: this.opciones.horarios, type: 'select', rules: [req], value: this.evento.idHorario,  input: (v) => setKey('idHorario', v), itemValue: 'idHorario', itemText: (item) => `${item.nombreHorario} (${item.siglas})`  },
				{ titulo: 'Nº Adultos', type: 'text', rules: [isNumber], value: this.evento.nAdultos,  input: (v) => setKey('nAdultos', v)},
			].filter(input => input.filter == null || input.filter )

			// BLOQUEO
			if (this.tipoEvento.idTipoEvento == 6) return [
				{ titulo: 'Espacio', options: this.opciones.espacios, type: 'select', rules: [req], value: this.evento.idEspacio,  input: (v) => setKey('idEspacio', v), itemValue: 'idEspacio', itemText: (item) => `${item.nombreEspacio} ${item.siglas ? `(${item.siglas})` : '' } `},
				{ titulo: 'Salón', placeholder: '- No hay datos disponibles -', options: this.opciones.salones, type: 'select', value: this.evento.idSalon,  input: (v) => setKey('idSalon', v), itemValue: 'idSalon',  itemText: (item) => `${item.nombreSalon} (${item.siglas})`, filter: (this.espacio && !this.espacio.libre)  },
			].filter(input => input.filter == null || input.filter )
		}
  },
  methods: {
    async vincularBoda(){
      const boda = await this.$refs.vincular.open();
      if (!boda) return;
      this.$set(this.evento, 'novios', 'PB ' + boda.nombrePareja)
      this.$set(this.evento, 'fechaBodaReal', boda.dia + " " + boda.year)
      if (boda.espacio == "CATERING ARAGÓN" || boda.espacio == "CATERING CATALUÑA") {
        this.$set(this.evento, 'ubBodaReal', boda.salon || '')
      }
      else this.$set(this.evento, 'ubBodaReal', boda.espacio || '')
      this.$set(this.evento, 'ubBodaRealAux', boda.espacio || '')
      this.$set(this.evento, 'breveDato', (boda.breveDato || ' '))
      try {
        await axios({
          method: 'POST',
          url: `/listado/vincular/${boda.idAdHoc}`,
        });
        this.vinculame = boda.idAdHoc
        this.$root.$emit("snack", "Ficha actualizada correctamente");
      } catch (e) {
        this.$root.$emit("snack", "Error al actualizar la ficha");
      }
    },
    getEstados() {
      return new Promise(async (resolve) => {
        const { data } = await axios(`/opciones/estados`);
        this.estados = data;
        resolve();
      });
    },
    parseDate,
		validar() {
			return this.evento.fechaInicioEvento && this.$refs.form.validate()
		},
    getTiposEvento() {
      return new Promise(async (resolve) => {
        const { data } = await axios("/opciones/tipoEventos");
        this.opciones.tipoEventos = data;
        resolve();
      });
    },
    getTiposBoda() {
      return new Promise(async (resolve) => {
        const { data } = await axios("/opciones/tipoBodas");
        this.opciones.tipoBoda = data;
        resolve();
      });
    },
    getEspacios() {
      return new Promise(async (resolve) => {
        const { data } = await axios("/opciones/espacios");
        this.opciones.espacios = data;
        resolve();
      });
    },
    getViasReservas() {
      return new Promise(async (resolve) => {
        const { data } = await axios("/opciones/viasReserva");
        this.opciones.viasReserva = data;
        resolve();
      });
    },
    getHorarios() {
      return new Promise(async (resolve) => {
        const { data } = await axios("/opciones/horarios");
        this.opciones.horarios = data;
        resolve();
      });
    },
    getSalones(v) {
      return new Promise(async (resolve) => {
        if (v == null) v = this.evento.idSalon;
        const { data } = await axios(`/opciones/salones?idEspacio=${v}`);
        this.opciones.salones = data;
        resolve();
      });
    },
    async getEvento() {
      try {
        const { data } = await axios({
          url: `/eventos/${this.$route.params.id}`,
        });

        this.evento = { ...data, ...JSON.parse(data.datos) };

        if (!Boolean(this.evento.editable)) {
          this.$root.$emit("snack", "Este evento no se puede editar");
          this.$router.push({ name: "Eventos" });
          return;
        }

        this.$set(
          this.evento,
          "fechaInicioEventoAux",
          new Date(this.evento.fechaInicioEvento)
            .toISOString()
            .slice(0, 19)
            .replace("T", " ")
        );

        if(this.$route.params.id){
          const res = await axios({
            url: `/presupuestos/evento/${this.$route.params.id}`,
          });
          if (res.data){
            this.existePpto = true
            this.idPresupuesto = res.data.idPresupuesto
            Object.assign(this.presupuesto, JSON.parse(res.data.datos))
          }
        }
      } catch (e) {
        if (e.response.status == 404)
          this.$root.$emit("snack", "No se ha encontrado el evento");
        this.$router.push({ name: "Eventos" });
      }
    },
    async sendEvento() {
      if (!this.validar()) return;
      this.sending = true;
      if (this.vinculame){
        try{
          await axios({
            method: 'POST',
            url: `/listado/vincularFecha`,
            data:{
              fecha: this.evento.fechaInicioEvento.split(" ")[0],
              idAdHoc: this.vinculame
            }
          });
        }
        catch (err){
          this.$root.$emit("snack", "Error al vincular");
          console.log(err);
        }
      }
      try {
        // const evento = { datos : {...this.evento} }
        if (this.idEstado != null) this.evento.idEstado = this.idEstado
        const { data } = await axios({
          url: "/eventos/nuevo",
          method: "POST",
          data: {
            evento: this.evento,
            idEvento: this.$route.params.id || null,
          },
        });
        this.$root.$emit("snack", "El evento se ha guardado correctamente");
        if (this.existePpto){
          var id = data.idEvento;
          this.presupuesto.fecha = new Date().toISOString().split('T')[0]
          this.presupuesto.estado = 2
          this.presupuesto.confirmado = [0, 1, 0, 0, 0, 0][this.evento.idEstado-1]
          this.presupuesto.fechaEv = this.evento.idTipoEvento != 5 ? this.evento.fechaInicioEvento : (new Date(this.evento.fechaBodaReal + " " + new Date().getFullYear()) < new Date() ? (new Date(this.evento.fechaBodaReal + " " + new Date().getFullYear()+1).getFullYear() + "-" + ('0'+(new Date(this.evento.fechaBodaReal + " " + new Date().getFullYear()).getMonth()+1)).slice(-2) + "-" + ('0'+(new Date(this.evento.fechaBodaReal + " " + new Date().getFullYear()).getDate())).slice(-2)) : (new Date(this.evento.fechaBodaReal + " " + new Date().getFullYear()).getFullYear() + "-" + ('0'+(new Date(this.evento.fechaBodaReal + " " + new Date().getFullYear()).getMonth()+1)).slice(-2) + "-" + ('0'+(new Date(this.evento.fechaBodaReal + " " + new Date().getFullYear()).getDate())).slice(-2)))
          this.presupuesto.tipo = this.evento.idTipoEvento
          this.presupuesto.cliente = (this.evento.novios || this.evento.nino || this.evento.tipoCelebracion || this.evento.clientes[0].nombreCompleto || '')
          if (this.evento.idTipoEvento != 5) {
            if(this.evento.idEspacio == 9) this.presupuesto.espacio = 10
            else if(this.evento.idEspacio == 10) this.presupuesto.espacio = 9
            else this.presupuesto.espacio = this.evento.idEspacio
            if (this.evento.idEspacio == 10 || this.evento.idEspacio == 9) {
              this.presupuesto.ubicacion = this.evento.ciudadCat
            }
            else{
              this.presupuesto.ubicacion = this.evento.nombreSalon
            }
          }
          else{
            switch (this.evento.ubBodaRealAux) {
              case "LALANNE":
                this.presupuesto.espacio = 4    
                break;
              case "PISCIS":
                this.presupuesto.espacio = 1
                break;
              case "HOTEL":
                this.presupuesto.espacio = 2
                break;
              case "LAUS":
                this.presupuesto.espacio = 3
                break;
              case "LLOTJA":
                this.presupuesto.espacio = 12  
                break;
              case "VDV":
                this.presupuesto.espacio = 13
                break;
              case "CATERING CATALUÑA":
                this.presupuesto.espacio = 10
                break;
              case "CATERING ARAGÓN":
                this.presupuesto.espacio = 9
                break;
              default:
                break;
            }
            if (this.presupuesto.espacio == 10 || this.presupuesto.espacio == 9){
              this.presupuesto.ubicacion = this.evento.ubBodaReal
            }
          }
          this.presupuesto.tipoPpto = 1
          this.presupuesto.supervisa = 10

          const res = await axios({
            url: "/presupuestos/linked/"+ id,
            method: "POST",
            data: {
              items: JSON.stringify({
                datos: this.presupuesto,
                idPresupuesto: this.idPresupuesto,
              })
            },
          });
          console.log(res.data);
          this.$root.$emit("snack", "El presupuesto se ha creado correctamente");
          this.idPresupuesto = res.data
        }
        if(this.abrirPpto) this.$router.push({ name: "datosPresupuesto", params: { idPresupuesto : this.idPresupuesto } });
        else this.$router.push({ name: "Index" });
        // if (this.$route.params.id)
        // else this.$router.push("/eventos/" + data.idEvento);
      } catch (error) {
        console.log(error);
        this.$root.$emit("snack", "No se ha podido guardar el evento");
      } finally {
        this.sending = false;
      }
    },
    async cambioEstadoNuevo() {
      const confirmation = await this.$refs.cambioEstadoNuevo.open(
        "Cambiar estado",
      );
      if (!confirmation) return;
      this.estado = confirmation;
    },
    async deleteEvent() {
      const confirmation = await this.$refs.confirm.open(
        "Eliminar el evento",
        "¿Estás seguro? <br><br> Esta acción no se puede deshacer",
        { color: "error" }
      );
      if (!confirmation) return;

      this.sending = true;
      try {
        const { data } = await axios({
          url: `/eventos/${this.$route.params.id}`,
          method: "DELETE",
        });
        this.$root.$emit("snack", "El evento se ha eliminado correctamente");
        this.$router.push({ name: "Eventos" });
      } catch (error) {
        this.$root.$emit("snack", "No se ha podido eliminar el evento");
      } finally {
        this.sending = false;
      }
    },
    async duplicarEvento() {
      const confirmation = await this.$refs.confirm.open(
        "Duplicar el evento",
        "Estás a punto de duplicar este evento",
        { color: "info" }
      );
      if (!confirmation) return;

      this.sending = true;
      try {
        const { data } = await axios({
          url: `/eventos/${this.$route.params.id}/duplicate`,
          method: "POST",
        });
        this.$root.$emit("snack", "El evento se ha duplicado correctamente (Cambiado a la copia)");
        this.$router.push({ name: "Evento", params: { id : data.idEvento } });
      } catch (error) {
        this.$root.$emit("snack", "No se ha podido duplicar el evento");
      } finally {
        this.sending = false;
      }
    },
		async getEventos() {
      const { data } = await axios({ url: "/eventos/", });

      this.eventos = data.map((evento) => {
        evento.datos = JSON.parse(evento.datos);
				return {
					date : (evento.datos.fechaInicioEvento.substr(0, 10)),
					color : (evento.colorTipoEvento)
				}
      });
    },
		eventosCalendario(date) {
			return this.eventos.filter(ev => ev.date == date).map(ev => ev.color).slice(0, 3)
		},
    escoge(tipo){
      if (!this.evento.idEvento && tipo==1) this.$router.push({ path: "/listado/boda" });
      else if (!this.evento.idEvento && tipo==2) this.$router.push({ path: "/listado/comunion" });
      else this.$set(this.evento, 'idTipoEvento', tipo)
    }
  },
  async mounted() {
    await Promise.all([
      this.getTiposEvento(),
      this.getTiposBoda(),
      this.getEspacios(),
      this.getViasReservas(),
      this.getHorarios(),
    ]);
    if (this.$route.params.id) {
      this.getEvento();
    }
    
		this.getEventos();
  },
  watch: {
    "evento.idEspacio"(v, o) {
      this.getSalones(v);
			if (this.espacio && o)
				this.evento.idSalon = null
    },
  },
};
</script>
